export function getFileNameFromContentDisposition(contentDisposition) {
    let fileName = 'unknown';
    if (!contentDisposition) {
        return fileName;
    }
    
    const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-.]+)(?:; ?|$)/i;
    const asciiFilenameRegex = /^filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i;
    
    if (utf8FilenameRegex.test(contentDisposition)) {
        fileName = decodeURIComponent(utf8FilenameRegex.exec(contentDisposition)[1]);
    } else {
        // prevent ReDos attacks by anchoring the ascii regex to string start and
        //  slicing off everything before 'filename='
        const filenameStart = contentDisposition.toLowerCase().indexOf('filename=');
        if (filenameStart >= 0) {
            const partialDisposition = contentDisposition.slice(filenameStart);
            const matches = asciiFilenameRegex.exec(partialDisposition);
            if (matches != null && matches[2]) {
                fileName = matches[2];
            }
        }
    }
    
    return fileName;
};